import React from 'react';
// import sections
// import Hero from '../components/sections/Hero';
import GenericSection from '../components/sections/GenericSection';
import FeaturesTiles from '../components/sections/FeaturesTilesAbout';

const About = () => {

  return (
    <>
      <GenericSection bottomDivider>
        <div>
          <h1 style={{ textAlign: 'center' }}>About</h1>
        </div>
        
        <div>
          <h3>Who we are.</h3>
          <p>
          We are a group of students from Germany and the UK who have been playing video games for as long as we can remember. We are big fans of CS:GO, playing since 2013 with a combined 5000+ competitive matches between us.
          </p>
        </div>
        <h3>Meet the team.</h3>
        <FeaturesTiles />
        <div>
          <h3>Why ezscrims?</h3>
          <p>
            As keen competitive CS:GO players, we understand the importance of high-quality servers and ease of use when it comes to third-party services.
          </p>
          <p>
            While other well-known platforms offer scrim services for players, we wanted to focus on offering an affordable and more importantly, easy-to-use alternative for your whole community. We offer this on a per-community basis and not a per-player basis.
          </p>
          {/* <p>
            Ezscrims lets you host scrims faster, and effortlessly while allowing communities to focus on themselves, without the worries around hosting.
          </p> */}
          <p>
            We know how Discord has become the focal point for many CS:GO communities. For this reason, we offer seamless integration with Discord, so you can start pugging effortlessly and focus on your community, without having to worry about hosting.
          </p>
        </div>

        
      </GenericSection>
      
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default About;