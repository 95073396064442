import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Faq from './views/Faq';
import About from './views/About';
import Pricing from './views/Pricing';

// Initialize Google Analytics


const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  const handleAcceptCookie = () => {
    // if (process.env.REACT_APP_GA_CODE) {
    ReactGA.initialize("UA-191418512-1");
    const page = location.pathname;
    trackPage(page);
    // }
    // UA-191418512-1
  };

  useEffect(() => {
    
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/faq" component={Faq} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />
        </Switch>
      )} />
      <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} style={{ background: "#167790", fontSize: "16px" }}
        buttonStyle={{ color: "#000", background: "#FFFF50", fontSize: "16px", borderRadius: "2px" }}
        declineButtonText="Decline Cookies" buttonText="Accept Cookies"
        declineButtonStyle={{ color: "#000", background: "#C8D3D5", fontSize: "16px", borderRadius: "2px" }}>
        Ezscrims uses cookies to enhance the user experience.
      </CookieConsent>
      </div>
  );
}

export default App;