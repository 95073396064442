import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
/*import Hero from '../components/sections/Hero';*/

const Faq = () => {

  return (
    <div id='faq'>
      {/*<Hero className="illustration-section-01" />*/}
      <GenericSection bottomDivider>
        <div>
          <h1 style={{ textAlign: 'center' }}>FAQ</h1>
        </div>
        <div>
          <ol>
            <li>
              <h4>
                What is ezscrims?
              </h4>
              {/*<br/>*/}
              <p>
                Ezscrims is a Discord bot created to make pugging with your friends as fast and simple as possible. With this bot you can have a custom CS:GO server set up for you and your friends in a matter of seconds.
              </p>
              <br />
            </li>
            <li>
              <h4>
                How do I invite the bot to my server?
              </h4>
              {/*<br/>*/}
              <p>
                During the alpha, it is not possible to invite the Discord bot to your Discord server yourself. If you'd like to try out ezscrims, feel free to contact us on our discord server.
              </p>
              {/* <p>
                Visit the <a style={{ color: 'yellow' }} href='https://www.ezscrims.com/'>homepage</a> and click on "add to server". This will prompt you to sign into your Discord account and choose bot permissions. Lastly, choose the server you want to add the bot to and click add!
              </p> */}
              <br />
            </li>
            <li>
              <h4>
                What permissions does the bot need and why?
                </h4>
              {/*<br/>*/}
              <ol>
                <li>
                  Read Messages - Required to read bot commands.
                </li>
                <li>
                  Send Messages - Required to post scrim data in chat.
                </li>
                <li>
                  Embed Links - Required to post scrim data in chat.
                </li>
                {/* <li>
                  Add Reactions - Required to add reactions for voting for a server region and aborting a scrim.
                </li> */}
                <li>
                  Manage messages (optional) - Required to remove reactions on the server voting message.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <h4>
                How do I start a scrim?
              </h4>
              {/*<br/>*/}
              <p>
                Simply type "!scrim" into a channel in your Discord server to start pugging.
              </p>
              <br />
            </li>
            <li>
              <h4>
                What customisation options are available on the server?
              </h4>
              {/*<br/>*/}
              <p>
                Our scrim servers come with a scrim plugin which allows players to vote for maps, choose captains and team members and more!
              </p>
              <br />
            </li>
            <li>
              <h4>
                Can I play with less than 10 people?
              </h4>
              {/*<br/>*/}
              <p>
                Absolutely! Using the .setup command on your server, you can customise the number of players on each team.
              </p>
              <br />
            </li>
            <li>
              <h4>
                What server regions are available?
              </h4>
              {/*<br/>*/}
              <div>
                <strong>
                  EU
                </strong>
                <ul>
                  <li>
                    Amsterdam
                  </li>
                  <li>
                    Barcelona
                  </li>
                  <li>
                    Bristol
                  </li>
                  <li>
                    Dusseldorf
                  </li>
                  <li>
                    Istanbul
                  </li>
                  <li>
                    Moscow
                  </li>
                  <li>
                    Stockholm
                  </li>
                  <li>
                    Strasbourg
                  </li>
                  <li>
                    Warsaw
                  </li>
                </ul>
                <strong>
                  NA
                </strong>
                <ul>
                  <li>
                    Chicago
                  </li>
                  <li>
                    Dallas
                  </li>
                  <li>
                    Los Angeles
                  </li>
                  <li>
                    New York City
                  </li>
                  <li>
                    Portland
                  </li>
                </ul>
                <strong>
                  SEA/OCE
                </strong>
                <ul>
                  <li>
                    Singapore
                  </li>
                  <li>
                    Sydney
                  </li>
                </ul>
              </div>
              <br />
            </li>
            <li>
              <h4>
                Why can't I connect to the CS:GO server?
              </h4>
              {/*<br/>*/}
              <p>
                Sometimes servers can take up to 60 seconds to boot, so if you can't connect straight away don't worry! If you still have issues connecting, try reconnecting after 10s. Otherwise, get in touch with our support team.
              </p>
              <br />
            </li>
            <li>
              <h4>
                How do I contact support?
              </h4>
              {/*<br/>*/}
              <p>
                The best place to contact us is in the <a style={{ color: '#7289DA' }} href='https://discord.gg/PADtEF9HK7'>#support</a> channel on our Discord!
              </p>
              <br />
            </li>
            <li>
              <h4>
                Do you collect any personal data?
              </h4>
              {/*<br/>*/}
              {/* TODO: Extended privacy policy link */}
              <p>
                The only data we collect are the name and ID of any Discord servers that ezscrims is part of.
              </p>
              <br />
            </li>
            <li>
              <h4>
                What payment methods do you accept?
              </h4>
              {/*<br/>*/}
              <p>
                We are not taking any payments yet, but we aim to accept as many different payment methods as possible.
              </p>
              <br />
            </li>
          </ol>
        </div>
      </GenericSection>
      {/*<GenericSection /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </div>
  );
}

export default Faq;