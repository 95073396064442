import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'What\'s so special?',
    paragraph: 'The ezscrims bot sets up your private game on our high-performance servers at a moment\'s notice, so you can focus on your friends instead of managing your gameserver.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  !scrim
                  </div>
                <h3 className="mt-0 mb-12">
                  Lightning fast setup
                  </h3>
                <p className="m-0">
                Just say the word and ezscrims bot starts launching your server. Simply select your server region, and you will be ingame in no time. Designate team captains and have them draft their teams, vote for a map and get fragging! Rush into your games like you rush B.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <video autoPlay="autoplay" muted loop style={{borderRadius: "20px"}} >
                  <source src={require('./../../assets/videos/scrim_command.webm')} type="video/webm" />
                  <source src={require('./../../assets/videos/scrim_command.mp4')} type="video/mp4" />
                </video>
                {/* <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} /> */}
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Save your wallet
                  </div>
                <h3 className="mt-0 mb-12">
                  Affordable servers
                  </h3>
                <p className="m-0">
                You can rent a server around the clock, but you don't use it for the entire time you pay. With ezscrims you can find the perfect balance of cost and convenience, so you can keep playing, even when life demands an eco round.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">

                {/* <video autoplay="autoplay" loop style={{borderRadius: "20px"}}>
                  <source src={require('./../../assets/videos/flower.webm')} type="video/webm" />
                </video> */}

                <Image
                  src={require('./../../assets/images/ecoround.webp')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  style={{borderRadius: "20px"}} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Excellent service worldwide
                  </div>
                <h3 className="mt-0 mb-12">
                  High performance
                  </h3>
                <p className="m-0">
                Everyone runs faster with a knife. Except servers. Servers run faster with 128 ticks. That's why we exclusively use 128 tick servers in locations around the world, so you can enjoy the smoothest gaming experience possible.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <video autoPlay="autoplay" muted loop style={{borderRadius: "20px"}} >
                  <source src={require('./../../assets/videos/128tick.webm')} type="video/webm" />
                  <source src={require('./../../assets/videos/128tick.mp4')} type="video/mp4" />
                </video>
                {/* <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} /> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;